<template>
	<div class="createParks">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="人员名称：">
					<el-autocomplete v-model.trim="formInline.name" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
				<el-button type="primary" @click="modifyParkInfo($event, false)" icon="el-icon-plus">新增</el-button>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="tableHead"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<!-- 插槽操作栏固定属性operation -->
				<template #enabled="{ scope }">
					<el-switch inactive-text="否" active-text="是" v-model="scope.row.enabled" @change="changeOpen(scope.row)"> </el-switch>
				</template>
				<template #graphicAuthorization="{ scope }">
					<el-switch
						inactive-text="否"
						active-text="是"
						:disabled="!scope.row.enabled"
						v-model="scope.row.graphicAuthorization"
						@change="changeOpen(scope.row)"
					>
					</el-switch>
				</template>
				<template #telephoneAuthorization="{ scope }">
					<el-switch
						inactive-text="否"
						active-text="是"
						:disabled="!scope.row.enabled"
						v-model="scope.row.telephoneAuthorization"
						@change="changeOpen(scope.row)"
					>
					</el-switch>
				</template>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo($event, true, scope.row)" size="mini" type="primary"> 查看 </el-button>
					<el-button
						@click="modifyParkInfo($event, false, scope.row)"
						size="mini"
						:type="scope.row.enabled ? 'info' : 'warning'"
						:disabled="scope.row.enabled"
					>
						修改
					</el-button>
				</template>
			</Table>
		</div>
		<createView @getList="getList" ref="createView" :isShowDetail="isShowDetail" />
	</div>
</template>

<script>
import { getAge } from '@/utils/formatTime.js';
export default {
	name: 'infoManager',
	components: {
		Table: () => import('@/components/Table/table'),
		createView: () => import('./newDialog.vue'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				name: '',
				practiceCategoryDictionaryItemId: null,
				genderDictionaryItemId: null,
			},
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '姓名',
					prop: 'name',
					formatter: (row) => {
						return row.name || '-';
					},
				},
				{
					label: '性别',
					formatter: (row) => {
						return row.genderDictionaryItem?.title || '-';
					},
				},
				{
					label: '年龄',
					prop: 'birthDate',
					width: 70,
					formatter: (row) => {
						return row.birthDate ? getAge(row.birthDate) : '-';
					},
				},
				{
					label: '执业单位',
					formatter: (row) => {
						return row.medicalOrganization?.title || '-';
					},
				},
				// {
				// 	label: '医生编号',
				// 	formatter: (row) => {
				// 		return row.medicalOrganization?.title || '-';
				// 	},
				// },
				{
					label: '执业类别',
					width: 100,
					prop: 'practiceCategoryDictionaryItem',
					formatter: (row) => {
						return row.practiceCategoryDictionaryItem?.title || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
				},
				{
					label: '身份证号码 ',
					prop: 'identificationCode',
					formatter: (row) => {
						return row.identificationCode || '-';
					},
				},
				{
					label: '图文授权',
					prop: 'graphicAuthorization',
					width: 110,
				},
				{
					label: '电话授权',
					prop: 'telephoneAuthorization',
					width: 110,
				},
				{
					label: '是否启用',
					prop: 'enabled',
					width: 110,
				},
			],
			isShowDetail: false,
			options: [],
			filterLoading: false,
			sexDictionaryItemList: [],
			typeDictionaryItemList: [],
			link: '',
		};
	},
	props: [],
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		changeOpen(row) {
			this.$http
				.put(row._links.update.href, row)
				.then((res) => {
					if (res.data && res.data.success) {
						this.$message.success('操作成功');
						this.getList();
					}
				})
				.catch((e) => {});
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let data = {
				...this.formInline,
				...this.queryData,
			};
			this.$http
				.get(this.api['MedicalWorkers#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.name,
								};
							});
						} else {
							this.link = res.data._links.create.href || '';
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		modifyParkInfo(event, isShowDetail, row) {
			let data = {
				row: row || null,
				link: row ? row._links.update.href : this.link,
			};
			if (row) {
				this.$refs.createView.getDepartmentList(row.medicalOrganization.id);
			}
			this.$refs.createView.init(data);
			this.isShowDetail = isShowDetail;
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
.seeMore {
	color: #0d99ff;
}
</style>
